<template>
  <b-modal id="galleryModal" ref="galleryModal" hide-footer @hide="close">
    <template #modal-header>
      <h5>{{ $t("NEW") }}</h5>
      <b-button size="sm" @click="resetModal()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17.028"
          height="17.028"
          viewBox="0 0 17.028 17.028"
        >
          <path
            id="Icon_material-close"
            data-name="Icon material-close"
            d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
            transform="translate(-7.5 -7.5)"
            fill="#393939"
          />
        </svg>
      </b-button>
    </template>

    <form @submit.prevent="addGAL">
      <div class="center">
        <b-form-group :label="$t('WORDING') + '*'">
          <b-form-input
            v-model="v$.newGAL.titre.$model"
            :state="validateState('titre')"
            oninput="this.value = this.value.toUpperCase()"
            aria-describedby="titre-feedback"
          ></b-form-input>
          <error-handle
            :list="v$.newGAL.titre.$errors"
            id="titre-feedback"
          ></error-handle>
          <div v-if="erreurlist.titre" class="error-message">
            <ul v-if="Array.isArray(erreurlist.titre)">
              <span v-for="(e, index) in erreurlist.titre" :key="index">
                {{ e }}
              </span>
            </ul>
            <span v-else>{{ erreurlist.titre }}</span>
          </div>
        </b-form-group>
        <b-form-group :label="$t('PICTURE')">
          <b-form-file
            accept="image/*"
            v-model="newGAL.image"
            placeholder=""
            drop-placeholder=""
            max-file-size="1"
            ref="file"
            class="classfile"
            @input="filesize($event)"
          ></b-form-file>
          <div v-if="showErrorAlert" class="error-message">
            {{ $t("SIZEMAX") }}
          </div>
          <div v-if="v$.newGAL.image.$error" class="error-message">
            {{ $t("REQUIRED_FIELD") }}
          </div>
        </b-form-group>
      </div>

      <div class="actionModel">
        <b-alert variant="warning" show v-if="errorS">
          {{ errorS }}
        </b-alert>
        <b-button variant="success" type="submit">
          <div class="block-spinner">
            {{ $t("SAVE") }}
            <div v-if="getLoadinggallery" class="loading ml-2">
              <div class="spinner-border" role="status"></div>
            </div>
          </div>
        </b-button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { domains } from "@/environment";
import { required, maxLength } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import errorHandle from "../ui/errorHandle.vue";

export default {
  components: {
    errorHandle,
  },
  setup() {
    return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
  },
  data() {
    return {
      newGAL: {
        titre: null,
        image: null,
      },
      erreurlist: {
        titre: null,
      },
      favoris: null,
      galleryUrl: domains.gallery,
      errorS: null,
      showErrorAlert: false,
      box: "",
    };
  },

  validations() {
    return {
      newGAL: {
        titre: { required, maxLength: maxLength(100) },
        image: { required },
      },
    };
  },
  methods: {
    ...mapActions(["store_gallery", "allgallery"]),

    validateState(name) {
      const { $dirty, $error } = this.v$.newGAL[name];
      return $dirty ? !$error : null;
    },

    resetModal() {
      this.errorS = null;
      this.$refs["galleryModal"].hide();
      this.newGAL = {
        titre: null,
        image: null,
      };
      this.erreurlist = {
        titre: null,
      };
      this.showErrorAlert = false;
      this.$nextTick(() => {
        this.v$.$reset();
      });
    },

    close(bv) {
      if (this.$refs["galleryModal"].isShow) {
        if (bv.trigger == "backdrop") {
          bv.preventDefault();
          this.showMsgBox();
        }
      }
    },

    showMsgBox() {
      this.box = "";
      this.$bvModal
        .msgBoxConfirm(`${this.$t("CLOSE")}`, {
          title: `${this.$t("CONFIRM")}`,
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          cancelVariant: "danger",
          okTitle: `${this.$t("YES")}`,
          cancelTitle: `${this.$t("NO")}`,
          footerClass: "p-2",
          hideHeaderClose: false,
        })
        .then((value) => {
          if (value) {
            this.resetModal();
          }
        });
    },
    async addGAL() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      this.erreurlist = {
        titre: null,
      };
      this.errorS = null;
      var bodyFormData = new FormData();
      bodyFormData.append("titre", this.newGAL.titre);
      bodyFormData.append("image", this.newGAL.image);
      if (this.showErrorAlert == false) {
        await this.store_gallery(bodyFormData)
          .then(() => {
            this.resetModal();
            this.$emit("updateList");
          })
          .catch((err) => {
            if (this.isObject(err)) {
              for (const [key, value] of Object.entries(err)) {
                if (!this.erreurlist[key]) {
                  this.erreurlist[key] = value;
                }
              }
            } else {
              this.errorS = err;
            }
          });
      }
    },
    isObject(obj) {
      return Object.prototype.toString.call(obj) === "[object Object]";
    },
    filesize(event) {
      if (event && event.size > 1048576) {
        this.showErrorAlert = true;
      } else {
        this.showErrorAlert = false;
      }
    },
  },

  computed: {
    ...mapGetters(["getLoadinggallery"]),
  },
};
</script>
<style scoped>
.classfile {
  margin-bottom: 5px;
}
</style>
